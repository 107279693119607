import { AiOutlineArrowRight } from "react-icons/ai";

const Button = ({ text, classProps, scale }) => {



    return (<button className={`flex flex-row items-center justify-center gap-1 outline-none  rounded-t-xl rounded-b-lg py-1 px-4 border-b-[5px] ${classProps} transform  ${scale==='in'?'hover:scale-90':scale==='out'?'hover:scale-110':''} transition-all duration-200`}>
        <AiOutlineArrowRight /> {text}
    </button>);
}

export default Button;